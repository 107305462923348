.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiDrawer-paper {
  background-color: #eaf0f5 !important;
}

.active li {
  background-color: #bdccdc;
}

.no-spinners input[type="number"]::-webkit-inner-spin-button,
.no-spinners input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

.hidden {
  display: none;
}

.yellowLoadingBar {
  background-color: rgba(229, 217, 188, 1) !important;
}

.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 500px;
  background-color: white;
}

.sigCanvas {
  width: 62%;
  border: 1px solid black;
  background-color: white;
  height: 250px;
  margin-right: 5px;
}

.sigImage {
  display: block;
  max-height: 50px;
  margin: 0 auto;
  position: relative;
  bottom: 170px;
  right: -200px;
}

.sigImagBuyerRepresentative {
  display: block;
  max-height: 50px;
  margin: 0 auto;
  position: relative;
  bottom: 160px;
  right: -200px;
}

.sigImagSellerRepresentative {
  display: block;
  max-height: 50px;
  margin: 0 auto;
  position: relative;
  bottom: 220px;
  left: -270px;
}

.sigImagSeller {
  display: block;
  max-height: 50px;
  margin: 0 auto;
  position: relative;
  bottom: 200px;
  left: -270px;
}

.sigPreview {
  margin-top: -50px;
  display: flex;
  justify-content: center;
  gap: 5px;
}

ul li {
  text-wrap: wrap;
}

.Mui-selected {
  background-color: #047782 !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: #047782 !important;
}

.Mui-checked,
.MuiCheckbox-indeterminate {
  color: #047782 !important;
}

.quill {
  background-color: #fff;

  /* margin-left: auto;
  margin-right: auto;
  width: fit-content;
  display: flex;
  justify-content: center; */
}

.quill .ql-editor {
  max-width: 8.5in;
  min-height: 11in;
  padding: 1in;
}

.ql-toolbar.ql-snow {
  border: none;
}

.quill.dc-none .ql-toolbar {
  display: none;
}

.ql-toolbar {
  z-index: 10;
  position: absolute;
  margin-top: -41px;
  width: 575px;
}

.non-selectable .ql-editor {
  user-select: none;
  cursor: default;
}

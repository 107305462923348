.message {
  margin-bottom: 1px;
  transition: color 0.3s;
}

.message:last-of-type {
  display: inline-block;
}

.message:hover,
.container:not(:hover) .message:last-of-type {
  color: rgba(255, 69, 58, 1);
}
.message:hover:only-of-type,
.container:not(:hover) div.message:only-of-type {
  color: rgba(0, 0, 0, 0.87);
}

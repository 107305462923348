a,
a:visited {
  text-decoration: none !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(196, 196, 196, 1);
}

.content-whole-screen-height {
  height: calc(100vh - 64px);
}

/* Message Center */
.chat-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.message-groups-container > .message-group:not(:first-child) {
  margin-top: 20px;
}

/* Chat */
.message:last-child .paragraph {
  margin-bottom: 20px;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
